import React from "react";
import AboutV4 from "./section-components/about-v4";
import Features from "./section-components/features-v1";

import Footer from "./global-components/footer";
import TopBar from "./global-components/topbar";
import Navbar from "./global-components/navbar-v2";
const About_v1 = () => {
  return (
    <div>
      {/* <TopBar /> */}
      <Navbar />
      <AboutV4 />
      <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 " />
      {/* <Sponsor /> */}
      <Footer />
    </div>
  );
};

export default About_v1;
