import React from "react";
import ShogGrid from "./shop-components/shop-grid-v1";
import Footer from "./global-components/footer";
import TopBar from "./global-components/topbar";
import Navbar from "./global-components/navbar-v2";
const ShopGrid_V1 = () => {
  return (
    <div>
      {/* <TopBar /> */}
      <Navbar />
      <ShogGrid />
      <Footer />
    </div>
  );
};

export default ShopGrid_V1;
