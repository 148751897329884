import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV4 from "./components/home-v4";
import About from "./components/about";
import Faq from "./components/faq";
import ShopGrid from "./components/shop-grid";
import Contact from "./components/contact";

class Root extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={HomeV4} />
            <Route path="/hakkimizda" component={About} />
            <Route path="/faq" component={Faq} />
            <Route path="/urunler" component={ShopGrid} />
            <Route path="/iletisim" component={Contact} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("quarter"));
