import axios from "axios";
import React, { useEffect, useState } from "react";

const AboutV4 = () => {
  const [about, setAbout] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nerogrup.com.tr/admin/api/v1/about"
        );
        setAbout(response.data.sonuc);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <div className="ltn__about-us-area pt-120--- pb-90 mt-40 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 align-self-center">
            <div
              className="about-us-img-wrap about-img-left"
              style={{ position: "relative" }}
            >
              <img
                src={`https://nerogrup.com.tr/admin/assets/images/about/${about.aboutImage}`}
                alt="About Us Image"
                style={{
                  width: "100%",
                  height: "auto",
                  display: "block",
                  filter: "blur(3px)",
                }}
              />
              <div
                className="about-text-overlay"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                  padding: "20px",
                  borderRadius: "5px",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  width: "80%", // Burada genişlik ayarı ekleyin
                  maxWidth: "600px", // Maksimum genişlik ayarı
                }}
              >
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  Hakkımızda
                </h6>
                <h1 className="section-title" style={{ marginBottom: "10px" }}>
                  Nero Grup<span>.</span>
                </h1>
                <p>{about.aboutDetail}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .about-text-overlay {
            width: 90%;
            padding: 10px;
            top: auto;
            left: auto;
            transform: none;
            bottom: 10px;
            right: 10px;
          }
          p {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default AboutV4;
