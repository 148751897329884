import React from "react";
import Faq from "./section-components/faq-v1";
import Footer from "./global-components/footer";
import TopBar from "./global-components/topbar";
import Navbar from "./global-components/navbar-v2";

const FaqV1 = () => {
  return (
    <div>
      {/* <TopBar /> */}
      <Navbar /> <Faq />
      <Footer />
    </div>
  );
};

export default FaqV1;
