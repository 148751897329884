import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const NavbarV5 = () => {
  const [category, setCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const handleScroll = () => {
    const element = document.getElementById("urunler");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleSearch = (event) => {
    event.preventDefault();

    // Ürünler sayfasına yönlendir
    window.location.href = "/urunler";
  };
  useEffect(() => {
    const data = async () => {
      try {
        const response = await axios.get(
          "https://nerogrup.com.tr/admin/api/v1/product-category"
        );
        setCategory(response.data.sonuc);
      } catch (error) {}
    };

    data();
  }, []);
  const handleWhatsAppArama = () => {
    window.location.href = "tel:+0555 099 44 99";
  };

  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div>
      {/* HEADER AREA START (header-3) */}
      <header className="ltn__header-area ltn__header-3 section-bg-6---">
        {/* ltn__header-middle-area start */}
        <div className="ltn__header-middle-area">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo">
                  <div className="mobile-menu-toggle d-lg-none">
                    <a
                      href="#ltn__utilize-mobile-menu"
                      className="ltn__utilize-toggle"
                    >
                      <svg viewBox="0 0 800 600">
                        <path
                          d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                          id="top"
                        />
                        <path d="M300,320 L540,320" id="middle" />
                        <path
                          d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                          id="bottom"
                          transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                        />
                      </svg>
                    </a>
                  </div>
                  <Link to="/">
                    <img
                      src={publicUrl + "assets/img/Asset 31.png"}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="col header-contact-serarch-column d-none d-xl-block">
                <div className="header-contact-search">
                  {/* header-feature-item */}
                  <div className="header-feature-item">
                    <div className="header-feature-icon">
                      <i className="icon-phone" />
                    </div>

                    <div className="mini-cart-icon mini-cart-icon-2">
                      <a
                        href="#ltn__utilize-cart-menu"
                        className="ltn__utilize-toggle"
                        onClick={handleWhatsAppArama}
                      >
                        <span className="mini-cart-icon">
                          <i className="fa-solid fa-phone"></i>{" "}
                        </span>
                        <h6>
                          <span>İletişim</span>{" "}
                          <span className="ltn__secondary-color">
                            <a href="tel:+0555 099 44 99">0555 099 44 99</a>{" "}
                          </span>
                        </h6>
                      </a>
                    </div>
                  </div>
                  {/* header-search-2 */}
                  <div className="header-search-2">
                    <form onSubmit={handleSearch}>
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Arama Yapınız..."
                      />
                      <button type="submit">
                        <span>
                          <i className="icon-search"></i>
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col">
                {/* header-options */}
                <div className="ltn__header-options">
                  <ul>
                    <li className="d-none">{/* ltn__currency-menu */}</li>
                    <li className="d-none">
                      {/* header-search-1 */}
                      <div className="header-search-wrap">
                        <div className="header-search-1">
                          <div className="search-icon">
                            <i className="icon-search  for-search-show" />
                            <i className="icon-cancel  for-search-close" />
                          </div>
                        </div>
                        <div className="header-search-1-form">
                          <form id="#" method="get" action="#">
                            <input
                              type="text"
                              name="search"
                              defaultValue=""
                              placeholder="Arama Yapınız..."
                            />
                            <button type="submit">
                              <span>
                                <i className="icon-search" />
                              </span>
                            </button>
                          </form>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="mini-cart-icon mini-cart-icon-2">
                        <a
                          href="#ltn__utilize-cart-menu"
                          className="ltn__utilize-toggle"
                          onClick={handleWhatsAppArama}
                        >
                          <h6>
                            <span>WhatsApp</span>{" "}
                            <span className="ltn__secondary-color">
                              0555 099 44 99{" "}
                            </span>
                          </h6>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-middle-area end */}
        {/* header-bottom-area start */}
        <div className="header-bottom-area ltn__border-top ltn__header-sticky  ltn__sticky-bg-white ltn__primary-bg---- menu-color-white---- d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col header-menu-column justify-content-center">
                <div className="sticky-logo">
                  <div className="site-logo">
                    <Link to="/">
                      <img
                        src={publicUrl + "assets/img/Asset 31.png"}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="header-menu header-menu-2">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>
                        <li className="">
                          <Link to="/">Anasayfa</Link>
                        </li>
                        <li className="">
                          <Link to="/hakkimizda">Kurumsal</Link>
                          <ul>
                            <li>
                              <Link to="/hakkimizda">Hakkımızda</Link>
                            </li>
                            <li>
                              <Link to="/faq">Sıkça Sorulan Sorular</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="">
                          <Link to={"/urunler"} onClick={handleScroll}>
                            Ürünler
                          </Link>
                          <ul>
                            {category &&
                              category.map((data) => (
                                <li key={data.id}>
                                  <Link
                                    to={"/urunler" + "/" + data.categorySlug}
                                    onClick={handleScroll}
                                  >
                                    {data.categoryName}
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        </li>

                        <li>
                          <Link to="/iletisim">İletişim</Link>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* header-bottom-area end */}
      </header>
      {/* HEADER AREA END */}

      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <button className="ltn__utilize-close">×</button>
            <div className="site-logo">
              <Link to="/">
                <img
                  src={publicUrl + "assets/img/Asset 31.png"}
                  alt="Logo"
                  style={{ maxWidth: 150 }}
                />
              </Link>
            </div>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <Link to="/">Anasayfa</Link>
              </li>
              <li>
                <Link to="/hakkimizda">Kurumsal</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/hakkimizda">Hakkımızda</Link>
                  </li>
                  <li>
                    <Link to="/faq">Sıkça Sorulan Sorular</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/urunler">Ürünler</Link>
                <ul className="sub-menu">
                  {category &&
                    category.map((data) => (
                      <li key={data.id}>
                        <Link to={"/urunler" + "/" + data.categorySlug}>
                          {data.categoryName}
                        </Link>
                      </li>
                    ))}
                </ul>
              </li>

              <li>
                <Link to="/iletisim">İletişim</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarV5;
