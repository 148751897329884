import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const FeaturesV1 = () => {
  const [about, setAbout] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nerogrup.com.tr/admin/api/v1/about"
        );
        setAbout(response.data.sonuc);
      } catch (error) {
        console.error("Veri çekme sırasında bir hata oluştu:", error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="mb-80">
      <div className="container">
        <div className="row"></div>
        <div className="row ltn__custom-gutter--- justify-content-center go-top">
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
              <div className="ltn__feature-icon">
                <img src={"assets/img/icons/icon-img/22.png"} alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/service-details">Misyonumuz</Link>
                </h3>
                <p>{about.aboutMission}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <img src={"assets/img/icons/icon-img/21.png"} alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/service-details">Vizyonumuz</Link>
                </h3>
                <p>{about.aboutVision}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 col-12">
            <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
              <div className="ltn__feature-icon">
                <img src={"assets/img/icons/icon-img/23.png"} alt="#" />
              </div>
              <div className="ltn__feature-info">
                <h3>
                  <Link to="/service-details">Hedeflerimiz</Link>
                </h3>
                <p>{about.aboutTarget}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesV1;
