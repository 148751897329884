import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FaqV1 = () => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nerogrup.com.tr/admin/api/v1/question"
        );
        setFaq(response.data.sonuc);
      } catch (error) {
        console.error("Veri çekme sırasında bir hata oluştu:", error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="ltn__faq-area mb-100 mt-40">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__faq-inner ltn__faq-inner-2">
              <div id="accordion_2">
                {/* card */}
                {faq &&
                  faq.map((data, index) => (
                    <div className="card mb-4" key={index}>
                      <h6
                        className="collapsed ltn__card-title"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq-item-2-${index}`}
                        aria-expanded="false"
                      >
                        {data.questionTitle}
                      </h6>
                      <div
                        id={`faq-item-2-${index}`}
                        className="collapse"
                        data-bs-parent="#accordion_2"
                      >
                        <div className="card-body">
                          <p>{data.questionAnswer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="need-support text-center mt-100">
                <h2>
                  Hala yardıma ihtiyacın var mı? 7/24 destek için bize ulaşın:
                </h2>
                <div className="btn-wrapper mb-30 go-top">
                  <Link to="/iletisim" className="theme-btn-1 btn">
                    İletşime Geç
                  </Link>
                </div>
                <h3>
                  <i className="fas fa-phone" /> +0312 244 44 10
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqV1;
