import axios from "axios";
import React, { useEffect, useState } from "react";

const ContactInfo = () => {
  const [contact, setContact] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nerogrup.com.tr/admin/api/v1/contact"
        );
        setContact(response.data.sonuc);
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <div className="ltn__contact-address-area mb-90 mt-40">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img src={"assets/img/icons/10.png"} alt="Icon Image" />
              </div>
              <h3>E-posta</h3>
              {contact && contact.email && (
                <>
                  {Object.values(contact.email).map((email, index) => (
                    <div>{email}</div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img src={"assets/img/icons/11.png"} alt="Icon Image" />
              </div>
              <h3>Telefon Numarası</h3>
              {contact && contact.phone && (
                <>
                  {Object.values(contact.phone).map((phone, index) => (
                    <div>{phone}</div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img src={"assets/img/icons/12.png"} alt="Icon Image" />
              </div>
              <h3>Adres</h3>
              <p>{contact.address}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
