import axios from "axios";
import React, { useEffect, useState } from "react";

const Map = () => {
  const [contact, setContact] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://nerogrup.com.tr/admin/api/v1/contact"
        );
        setContact(response.data.sonuc);
      } catch (error) {
      }
    };

    fetchData();
  }, []);
  return (
    <div className="google-map">
      <iframe
        src={contact.map}
        width="100%"
        height="100%"
        frameBorder={0}
        allowFullScreen
        aria-hidden="false"
        tabIndex={0}
      />
    </div>
  );
};

export default Map;
