import React from "react";
import TopBar from "./global-components/topbar";
import Navbar from "./global-components/navbar-v2";
import Category from "./section-components/category-v3";
import Service from "./section-components/service-v2";
import Footer from "./global-components/footer";
import BannerV2 from "./section-components/banner";

const Home_V1 = () => {
  return (
    <div>
      {/* <TopBar /> */}
      <Navbar />
      {/* <BannerV2 /> */}

      <Category />
      <Service />
      <Footer />
    </div>
  );
};

export default Home_V1;
