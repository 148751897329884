import React from "react";
import ContactInfo from "./section-components/contact-info";
import ContactForm from "./section-components/contact-form";
import Map from "./section-components/map";
import Footer from "./global-components/footer";
import TopBar from "./global-components/topbar";
import Navbar from "./global-components/navbar-v2";

const ContactV1 = () => {
  return (
    <div>
      {/* <TopBar /> */}
      <Navbar />
      <ContactInfo />
      <Map />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactV1;
