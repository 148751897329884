import axios from "axios";
import React, { useEffect, useState } from "react";

const ShopGridV1 = () => {
  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.href;
        const productCategorySlug = url.split("urunler/")[1];

        const apiUrl = productCategorySlug
          ? `https://nerogrup.com.tr/admin/api/v1/product/${productCategorySlug}`
          : `https://nerogrup.com.tr/admin/api/v1/product`;

        const response = await fetch(apiUrl);

        const data = await response.json();
        if (data.status === 404 || data.sonuc.length === 0) {
          setProduct([]);
        } else {
          setProduct(data.sonuc);
        }
      } catch (error) {
        console.error("Veri çekme sırasında bir hata oluştu:", error.message);
        setProduct([]);
      }
    };

    fetchData();
  }, [window.location.href]);

  const handleQuickView = (data) => {
    setSelectedProduct(data);
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = product.filter((data) =>
      data.productName.toLowerCase().includes(term.toLowerCase())
    );

    setFilteredProducts(filtered);
  };

  if (product.length === 0) {
    return (
      <p className="urun ltn__product-area ltn__product-gutter mb-80 mt-80 ">
        Bu kategoride ürün bulunmamaktadır.
      </p>
    );
  }
  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-80 mt-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__shop-options">
                <ul>
                  <li>
                    <div className="ltn__grid-list-tab-menu ">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_product_grid"
                        >
                          <i className="fas fa-th-large" />
                        </a>
                        <a data-bs-toggle="tab" href="#liton_product_list">
                          <i className="fas fa-list" />
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content" id="urunler">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      <div className="col-lg-12">
                        {/* Search Widget */}
                        <div className="ltn__search-widget mb-60">
                          <form action="#">
                            <input
                              type="text"
                              name="search"
                              placeholder="Ürün adına göre arama yapınız..."
                              value={searchTerm}
                              onChange={handleSearch}
                            />
                          </form>
                        </div>
                      </div>
                      {(filteredProducts.length > 0
                        ? filteredProducts
                        : product
                      ).length > 0 ? (
                        (filteredProducts.length > 0
                          ? filteredProducts
                          : product
                        ).map((data) => (
                          <div
                            className="col-lg-3 col-sm-6 col-12"
                            key={data.productId}
                          >
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                              <div className="">
                                <img
                                  src={`https://nerogrup.com.tr/admin/assets/images/products/${data.productImage}`}
                                  alt="#"
                                  style={{ width: "100%", height: "260px" }}
                                />
                              </div>

                              <div className="product-info">
                                <h2 className="product-title go-top product-price">
                                  {data.productName}
                                </h2>
                                <div className="product-hover-action">
                                  {/* büyütme listeye ekleme ve artı butonları dikeyde */}
                                  <ul>
                                    <li className="mt-3">
                                      <a
                                        href="#"
                                        title="Quick View"
                                        data-bs-toggle="modal"
                                        data-bs-target="#quick_view_modal"
                                        onClick={() => handleQuickView(data)}
                                      >
                                        <i className="flaticon-expand" />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Bu kriterlere uygun ürün bulunmamaktadır.</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="ltn__search-widget mb-30">
                          <form action="#">
                            <input
                              type="text"
                              name="search"
                              value={searchTerm}
                              onChange={handleSearch}
                              placeholder="Ürün adına göre arama yapınız..."
                            />
                            <button type="submit">
                              <i className="fas fa-search" />
                            </button>
                          </form>
                        </div>
                      </div>
                      {(filteredProducts.length > 0
                        ? filteredProducts
                        : product
                      ).length > 0 ? (
                        (filteredProducts.length > 0
                          ? filteredProducts
                          : product
                        ).map((data) => (
                          <div
                            className="col-lg-12 "
                            key={data.productId}
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 ">
                              <div
                                className="product-img"
                                style={{ marginRight: "20px" }}
                              >
                                <img
                                  src={`https://nerogrup.com.tr/admin/assets/images/products/${data.productImage}`}
                                  alt="#"
                                  style={{
                                    width: "100%",
                                    maxHeight: isMobile ? "none" : "280px",
                                  }}
                                />
                              </div>
                              <div className="product-info">
                                <h2 className="product-title go-top ">
                                  {data.productName}
                                </h2>
                                <p style={{ whiteSpace: "pre-line" }}>
                                  {data.productDetail}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Bu kriterlere uygun ürün bulunmamaktadır.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* büyütmeye bastığımda açılan modal */}
      <div className="ltn__modal-area ltn__quick-view-modal-area">
        <div className="modal fade" id="quick_view_modal" tabIndex={-1}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setSelectedProduct(null)}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>

              <div className="modal-body">
                {selectedProduct && (
                  <div className="ltn__quick-view-modal-inner">
                    <div className="modal-product-item">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <div className="modal-product-img">
                            <img
                              src={`https://nerogrup.com.tr/admin/assets/images/products/${selectedProduct.productImage}`}
                              alt="#"
                              style={{
                                width: "400px",
                                height: "300px",
                                marginBottom: "20px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="modal-product-info">
                            <h3 className="product-price ">
                              {selectedProduct.productName}
                            </h3>
                            {/* Add other product details as needed */}
                            <div className="">
                              <span>
                                {selectedProduct.productDetail
                                  .split("\n")
                                  .map((item, key) => (
                                    <React.Fragment key={key}>
                                      {item}
                                      <br />
                                    </React.Fragment>
                                  ))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridV1;
