import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CategoryV3 = () => {
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const data = async () => {
      try {
        const response = await fetch(
          "https://nerogrup.com.tr/admin/api/v1/product-category"
        );
        const data = await response.json();
        setCategory(data.sonuc);
      } catch (error) {}
    };

    data();
  }, []);
  return (
    <div className="ltn__banner-area pt-40 go-top">
      <div className="container">
        <div className="row">
          {category &&
            category.map((data) => (
              <div className="col-lg-4 col-md-6">
                <div
                  className="ltn__banner-item ltn__banner-style-4 text-color-white "
                  style={{
                    backgroundImage: `url(https://nerogrup.com.tr/admin/assets/images/category/${data.categoryImage})`,
                  }}
                >
                  <div className="ltn__banner-info">
                    <p> Nero Grup</p>
                    <h3>
                      <Link to={"/urunler/" + data.categorySlug}>
                        {data.categoryName}
                      </Link>
                    </h3>
                    <Link to={"/urunler/" + data.categorySlug}>
                      <mark> Ürünlere Git</mark>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryV3;
