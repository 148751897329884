import React, { Component } from "react";

class TopBar extends Component {
  render() {
    return (
      <div>
        <div className="ltn__header-top-area section-bg-6">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="mailto:info@nerogrup.com.tr">
                        <i className="icon-mail" /> info@nerogrup.com.tr
                      </a>
                    </li>
                    <li className="">
                      <i className="icon-placeholder" /> İstasyon mahallesi 2319
                      Cadde No : 2 / A Etimesgut / Ankara
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        {/* ltn__social-media */}
                        <div className="ltn__social-media">
                          <ul>
                            <li>
                              <a
                                href="https://www.instagram.com/nero.grup/"
                                title="Instagram"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fab fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://nerogrup.com.tr/"
                                title="Dribbble"
                              >
                                <i className="fab fa-dribbble" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
