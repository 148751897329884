import React, { Component } from "react";
import { Link } from "react-router-dom";

class BannerV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
        <div className="ltn__slider-11-inner">
          <div className="ltn__slider-11-active">
            {/* slide-item */}
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="md:col-lg-12 align-self-center">
                      <div className="slide-item-info">
                        <div className="slide-item-info-inner ">
                          <div className="slide-video mb-50 d-none">
                            <a
                              className="ltn__video-icon-2 ltn__video-icon-2-border"
                              href="https://www.youtube.com/embed/tlThdr3O5Qo"
                              data-rel="lightcase:myCollection"
                            >
                              <i className="fa fa-play" />
                            </a>
                          </div>
                          <h6 className="slide-sub-title white-color--- animated">
                            <span>
                              <i className="fas fa-home" />
                            </span>{" "}
                            Nero Grup Kurumsal Tedarik
                          </h6>
                          <h1 className="slide-title animated ">
                            Ara ve bul <br />
                            <span>Kaliteli</span> Ürünler
                          </h1>
                          <div className="slide-brief animated">
                            <p>
                              Geniş ürün yelpazemizle farklı ihtiyaçları
                              karşılayabilecek çeşitlilikte ürünler sunuyoruz.
                            </p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/urunler"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Ürünlere Git
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img hidden md:block">
                        <img
                          src={publicUrl + "assets/img/pro.png"}
                          alt="#"
                          className="d-none d-md-block" // Hide on screens larger than md
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BannerV2;
