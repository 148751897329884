import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer_v1 extends Component {
  componentDidMount() {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-5 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img
                        src={publicUrl + "assets/img/Asset 31.png"}
                        alt="Logo"
                        style={{maxWidth: 150}}
                      />
                    </div>
                  </div>
                  <p>
                    Biz Kimiz? Geniş ürün yelpazesi ile “ Doğru Kalite / Fiyat
                    Oranını ” sağlayarak tüketici beklentilerini karşılıyoruz.
                  </p>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            İstasyon mahallesi 2319 Cadde No : 2 / A Etimesgut /
                            Ankara
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+0312 244 44 10">312 244 44 10</a>
                          </p>
                          <p>
                            {" "}
                            <a href="tel:+0555 099 44 99">
                              0555 099 44 99
                            </a>{" "}
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto: info@nerogrup.com.tr">
                              info@nerogrup.com.tr
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Kurumsal</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/hakkimizda">Hakkımızda</Link>
                      </li>
                      <li>
                        <Link to="/faq">Sıkça Sorulan Sorular</Link>
                      </li>

                      <li>
                        <Link to="/iletisim">İletişim</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Ürünler</h4>
                  <div className="footer-menu go-top">
                    <ul>
                      <li>
                        <Link to="/urunler">Ürünler</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="footer-widget footer-newsletter-widget">
                  <h4 className="footer-title">Bize Ulaşın!</h4>
                  <p>
                    Bize mail göndererek ürünlerimiz hakkında fiyat teklifi
                    alabilrisiniz.
                  </p>
                  <div className="footer-newsletter">
                    <form action="#">
                      <input type="email" name="email" placeholder="Email*" />
                      <div className="btn-wrapper">
                        <button className="theme-btn-1 btn" type="submit">
                          <i className="fas fa-location-arrow" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer_v1;
