import React, { Component } from "react";
import { Link } from "react-router-dom";

class ServiceV2 extends Component {
  render() {
    return (
      <div className="ltn__feature-area pt-90 pb-90 go-top">
        <div className="container">
          <div className="row ltn__custom-gutter">
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-apartment" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <Link to="/service-details">İNOVATİF</Link>
                  </h4>
                  <p>Yaratıcı ve yenilikçi çözümler sunan bir firmayız.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6 active">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-park" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <Link to="/service-details">ÇEŞİTLİLİK</Link>
                  </h4>
                  <p>
                    Geniş ürün yelpazemizle farklı ihtiyaçları karşılayabilecek
                    çeşitlilikte ürünler sunuyoruz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-maps-and-location" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <Link to="/service-details">KALİTE</Link>
                  </h4>
                  <p>
                    En yüksek standartlarda üretilen ürünlerimizle
                    müşterilerimize kaliteyi garanti ediyoruz.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <div className="ltn__feature-icon">
                  <span>
                    <i className="flaticon-excavator" />
                  </span>
                </div>
                <div className="ltn__feature-info">
                  <h4>
                    <Link to="/service-details">DESTEK</Link>
                  </h4>
                  <p>
                    Satış öncesi ya da sonrası memnuniyet esaslı destek sistemi
                    sunuyoruz.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceV2;
